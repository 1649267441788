<template>
    <div class="bottom-box">
          <div  :class="'bottom-bit bottom-bit'+follow" @click="is_follow(arr,follow)">{{follow==2?'互相关注':(follow?'取消关注':'关注')}}</div>
     </div>
</template>
<script>
    import { setFollow } from "@api/tasting";
    import { Icon, Toast, Tab, Tabs } from "vant";
    export default {
        name: "FollowBit",
        data: function() {
            return {
                //onibdex:0,
            };
        },
        props: {
            follow: {
                type: Number,
                default:0
            },
            arr:{
                type:Array,
                default:[]
            }
        },
        components: {
            Icon, Toast, Tab, Tabs 
        },
        mounted: function() {
        },
        methods: {
           is_follow(item,index){
               let mag= '';
               if(!index){
                   mag="关注中";
                   index=1;
               }else{
                   index=0;
                   mag="取消中";
               }
               Toast.loading({
                    duration: 0,
                    message: mag,
                    forbidClick: true,
                });
               setFollow({
                   uid:item.uid,
                   to_uid:item.to_uid
               }).then(res=>{
                    Toast.clear();
                    this.$set(item,'is_follow',index);
               }).catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
              
           }
        }
    }
</script>
<style lang="scss">
    .bottom-box{
        display: flex;
        align-items: center;
        justify-content:center;
        .bottom-bit{
            padding: 0.08rem 0.2rem;
            border-radius: 0.5rem;
            border: 1px solid #e0e0e0;
            color: #ccc;
        }
        .bottom-bit0{
            padding: 0.03rem 0.33rem;
            background-color: #f00;
            border: 1px solid #f00;
            color: #fff;
        }
        .bottom-bit1,.bottom-bit2{
            border: 1px solid #e0e0e0;
            color: #ccc;
            font-size: 0.2rem;
        }
    }

    
    
</style>