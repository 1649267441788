<template>
    <div class="visitor">
        <form class="search-haed" action="javascript:void 0" @submit.prevent="query">
            <div class="search">
                <div class="iconfont iconsearch"></div>
                <input  type="search" placeholder="请输入用户昵称" v-model="keyword" />
                <div class="chongzhi" @click="setReset()" v-if="listshow" >重置</div>
            </div>
        </form>
        <div class="text-box">
            <span>累计 {{count}}</span>
            <span>今日 {{todayCount}}</span>
        </div>
        <div class="tabs-content"  v-for="(items,indexs) in list" :key="indexs">
            <div class="date-box">
                {{items.date}}·{{items.renCount}}人
            </div>
            <div class="list-box" v-for="(item,index) in items.data" :key="index">
                <div class="headimg">
                    <img :src="item.userinfo.avatar"/>
                </div>
                <div class="info">
                    <div class="info-boxs">
                        <div class="info-box">
                            <div class="info-one">
                                <span class="Ellipses1 name">{{item.userinfo.nickname}}</span>
                                <span class="level">{{item.userinfo.rank_name}}</span>
                                <span class="xinxin"><img :src="require('@assets/images/grade.png')" /></span>
                                <span class="repx">酒量值:{{item.userinfo.exppr}}</span>
                            </div>
                            <div class="info-two">
                                <span>粉丝{{item.userinfo.follow_number}}</span>
                                <span>内容{{item.userinfo.content}}</span>
                                <span class="city-box">{{item.userinfo.constellation}}|{{item.userinfo.city}}</span>
                            </div>
                        </div>
                        <!-- <div class="bottom-box">
                            <div  :class="'bottom-bit bottom-bit'+item.is_follow" @click="is_follow(item,item.is_follow)">{{item.is_follow==2?'互相关注':(item.is_follow?'取消关注':'关注')}}</div>
                        </div> -->
                        <FollowBit :arr="item" :follow="item.is_follow"></FollowBit>
                    </div>
                    <div class="info-source">{{item.tour_time}}/{{item.source}}</div>
                </div>
            </div>
        </div>
        <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
            </div>
            <div class="Loading" v-if="finished">没有更多了~</div>
        </div>
</template>

<script>
    import { getVisitor } from "@api/tasting";
    import FollowBit from './components/FollowBit';
    import { Icon, Toast, Tab  ,Loading } from "vant";
    export default {
        name: "visitor",
        data: function() {
            return {
                keyword:'',
                page:1,
                limit:10,
                list:[],
                loading:false,
                finished:false,
                listshow:false,
                count:0,
                todayCount:0,
                list_lengths:0,//列表长度
            };
        },
        components: {
            Icon, Toast, Tab ,Loading , FollowBit
        },
        created: function() {
            
        }, 
        mounted: function() {
            this.Loadmore();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            query(){
                this.getKeyword();
            },
            setdata(){
                this.loading = false;
                this.finished = false;
                this.page = 1;
                this.limit = 10;
                this.list=[];
                this.list_lengths=0;
            },
            setReset(){
                this.listshow = false;
                this.keyword = '';
                Toast.loading({
                    duration: 0,
                    message: '重置中...',
                    forbidClick: true,
                });
                this.setdata();
                this.Loadmore();
                Toast.clear();
            },
            Loadmore: function() {
                    if(this.loading) return;
                    if(this.finished) return;
                    this.visitor();
            },
           visitor:function(){
               this.loading = true;
               getVisitor({
                   page:this.page,
                   limit:this.limit,
                   keyword:this.keyword,
               }).then(res => {
                    this.count = res.data.count;
                    this.todayCount = res.data.today_count;
                    var listdata = res.data.list;
                    if(this.list_lengths>0 && listdata.length!=0){
                        if(listdata[0].date==this.list[this.list_lengths-1].date){
                            listdata[0].data.forEach((item)=>{
                                this.list[this.list_lengths-1].data.push(item);
                                this.list[this.list_lengths-1].renCount += 1;
                            })
                             listdata.forEach((item)=>{
                                listdata.shift(0);
                             })
                        }
                    }
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.loading = false;
                    this.page++;
                    this.list_lengths += listdata.length;
                    if(listdata.length<2){
                        this.finished = true;
                    }
               }).catch(err => {
                    this.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getKeyword(){
                getVisitor({
                    limit:30,
                    keyword:this.keyword,
                }).then(res => {
                    this.setdata();
                    var listdata = res.data.list;
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    //this.list = listdata;
                    this.listshow = true;
                }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            }
    },
           
}

</script>

<style scoped lang="scss">
    .visitor .tabs-content{
        background-color: #fff;
        //margin-top: 0.2rem;
    }
    .visitor .search-haed{
        width: 100%;
        height: 1rem;
        background-color: #fff;
        .search{
            margin: 0 auto;
            width: 90%;padding: 0.4rem 0;
            input{
                width: 100%;
                padding: 0.1rem 0.6rem;
                padding-right:0.8rem ;
                border: 1px solid #ccc;
                border-radius: 0.3rem;
                background-color: #ccc;
            }
            .iconfont{
                position: absolute;
                top: 0.5rem;
                left:7%;
            }
            .chongzhi{
                position: absolute;
                top: 0.5rem;
                right: 7%;
                z-index: 2;
            }
        }
    }
    .Loading{
        text-align: center;
        margin-top: 0.1rem;
        color: #ccc;
    }
    .visitor .text-box{
        background-color: #fff;
        padding:0.26rem ;
        margin-bottom: 0.2rem;
        span{
            padding: 0 0.2rem;
            padding-right:0 ;
            font-weight: 600;
            font-size: 0.4rem;

        }
    }
    .visitor .list-box .headimg{
        width: 40%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .visitor .info{
        width: 85%;
        border-bottom: 1px solid #eee;
        padding: 0.1rem 0;
        .info-source{
            padding: 0.1rem;
        }
        

    }
    .visitor .info-boxs{
        display: flex;
        width: 100%;
       
    }
    .visitor .info-box{
        max-width: 77%;
        width: 77%;
    }
    .visitor .list-box{
        display: flex;
        .headimg{
            width: 1.2rem;
            height: 1.2rem;
            padding: 0.1rem;
            img{
                width: 100%;
                height: 100%;
                border-radius: 1rem;
            }  
        }
        .info-box .info-one {
            display: flex;
            padding: 0.1rem;
            margin-top: 0.1rem;
            align-items: center;
            span{
              margin: 0 0.06rem;
            } 
            .name{
                font-weight: 600;
                font-size: 0.3rem;
                color: #4b6886;
                max-width: 20%;
            }
            .level{
                background-color: #f00;
                color: #fff;
                font-size: 0.2rem;
                padding:0.02rem 0.2rem; 
            }
            .xinxin{
                line-height: 0.1rem;
                img{
                width: 0.38rem;
                height: 0.38rem; 
                }
            }
            .repx{
                font-size: 0.2rem;
                color: #f00;
            }
            
        }
     }
    //  .tabs-content .bottom-box{
    //     display: flex;
    //     align-items: center;
    //     justify-content:center;
    //     .bottom-bit{
    //         padding: 0.08rem 0.2rem;
    //         border-radius: 0.5rem;
    //         border: 1px solid #e0e0e0;
    //         color: #ccc;
    //         margin-top: 0.2rem;
    //     }
    //     .bottom-bit0{
    //          padding: 0.08rem 0.3rem;
    //         background-color: #f00;
    //         border: 1px solid #f00;
    //         color: #fff;
    //     }
    //     .bottom-bit1,.bottom-bit2{
    //         border: 1px solid #e0e0e0;
    //         color: #ccc;
    //         font-size: 0.2rem;
    //     }
    // }
    
    .visitor .tabs-content .info-box span img{
            width: 100%;
            height: 100%;
    }
    .visitor .tabs-content .info-box .info-two{
        font-size: 0.25rem;
        font-weight: 400;
        padding: 0 0.1rem;
        span{
            margin-left: 0.1rem;
            color: #767676;
        }
        .city-box{
            font-weight: 800;
            padding-left: 0.2rem;
        }
    }
    .visitor .date-box{
        width: 90%;
        margin-left: 10%;
        border-bottom: 1px solid #eee;
        padding: 0.3rem 0rem;
        font-size: 0.34rem;
    }


</style>